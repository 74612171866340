import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'

import Layout from '../components/Layout'
import MasonGallery from '../components/MasonGallery'
import Menu from '../components/Menu'
import TextBanner from '../components/TextBanner'

export function IndexPageTemplate({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
  main,
  imageSources,
  premiumLogos,
}){

  return (
    <div>
      <div
        className="flicker full-width-image margin-top-0"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
          backgroundPosition: `center`,
        }}
      ></div>
      <TextBanner
        key="The best burger ever."
        text="The best burger ever."
        bannerClass="claim-text-container"
        highlightText="burger"
        highlightColor="green"
        animate={false}
      />
      <Menu />
      <TextBanner
        key="Made with premium ingredients."
        text="Made with premium ingredients."
        bannerClass="claim-two-text-container"
        highlightText="premium"
        highlightColor="green"
        animate={true}
      />
      <section className="section section--gradient">
        <div className="container logo-image-container">
          {premiumLogos.map((item, i) => {
            return item.logo ? (
              <a
                key={item.link}
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  className="logo-image"
                  fluid={item.logo.childImageSharp.fluid}
                  key={i}
                />
              </a>
            ) : (
              <div></div>
            );
          })}
        </div>
      </section>
      <TextBanner
        text="No ketchup needed."
        bannerClass="claim-two-text-container"
        highlightText="ketchup"
        highlightColor="#c5090e"
        animate={true}
      />
      <section className="section section--gradient">
        <MasonGallery photos={imageSources} />
      </section>
    </div>
  );
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data["IndexStuff"]

  const imageSources = frontmatter.gallery.images.map((item, i) => {
      return {
        src: item.image.childImageSharp.fluid.src,
        srcSet: item.image.childImageSharp.fluid.srcSet,
        sizes: item.image.childImageSharp.fluid.sizes,
        width: item.width,
        height: item.height
      }
  })

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
        imageSources={imageSources}
        premiumLogos={frontmatter.premiumlogos}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
query IndexPageTemplate {
  IndexStuff: markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
        gallery {
          images {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            width
            height
            text
          }
        }
        premiumlogos {
          logo {
            childImageSharp {
              fluid(maxWidth: 240, quality: 64) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          link
        }
      }
    }
}
`
