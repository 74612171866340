import React, { useRef, useEffect } from 'react'

const menuText = (onMouseEnter, onMouseLeave) => {
  let words = [
    { text: "The Burger.", color: "green" },
    { text: "22% Ground Beef.", color: "#ff7f7f" },
    { text: "Onions.", color: "#f6dce9" },
    { text: "Tillamook Cheddar.", color: "#F49F0D" },
    { text: "Mayo.", color: "black" },
    { text: "The Salad.", color: "green" },
    { text: "Butter Lettuce.", color: "#ADC954" },
    { text: "Dijon Lemon Vinegrette.", color: "#FDE999" },
    { text: "The Berry Crumble.", color: "#D53B41" },
    { text: "Ice Cream.", color: "black" },
    { text: "The Vanilla Milkshake.", color: "#F2EDC2" },
    { text: "Justin's Burgers.", color: "green" }
  ]

  let wordElements = words.map((word, index) => {
    return <span
      style={{color: word.color}}
      data-prlxo={Math.floor(Math.random() * (6 + 6) + 1)}
      data-prlxt={Math.floor(Math.random() * (6 + 6) - 6)}
      className="menu-item-span"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      title={word.text.split(" ").join(" ")}
    >
      {word.text.split("").map((char,index)=> {
        if(char === " "){ return <div dangerouslySetInnerHTML={{ __html: "&nbsp;" }}/>}
        return <div>{char}</div>
      })}
    </span>
  })

  return wordElements;
}

const Menu = () => {
  const banner = useRef(null);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  function handleScroll() {
    banner.current.querySelectorAll("span.menu-item-span").forEach((span,index)=>{
      const bannerTop = window.scrollY-banner.current.offsetTop+(window.innerHeight/6)
      const transform = bannerTop > 0
            ? 0
            :Math.floor(((bannerTop)*0.05)*2*span.dataset.prlxt),
          opacity = (1 + (bannerTop/(window.innerHeight/span.dataset.prlxo))) > 0
          ? (1 + (bannerTop/(window.innerHeight/span.dataset.prlxo))).toFixed(2)
          : 0;
      span.style.opacity = opacity;
      span.querySelectorAll("div").forEach((e)=> {
        e.style.transform = `translateX(${transform}px)`;
      })
    })
  }

  function onMouseEnter(e) {
    banner.current.style.backgroundColor = e.currentTarget.style.color
  }

  function onMouseLeave(e) {
    banner.current.style.backgroundColor = 'white';
  }
  return (
    <section ref={banner} className="section section--gradient menu-section">
      <div className="container">
        <div className="column is-12 has-text-centered">
          <h1
            className="menu-text-container"
          >
            <span>The Menu.</span>
            {menuText(onMouseEnter, onMouseLeave)}
          </h1>
        </div>
      </div>
    </section>
  );
}
export default Menu
