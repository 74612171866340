import React, { useEffect, useRef } from 'react'

const TextBanner = ({text, bannerClass, highlightText, highlightColor, animate}) => {
  const banner = useRef(null);

  useEffect(() => {
    if(animate){
      window.addEventListener('scroll', handleScroll);
      handleScroll();
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  function handleScroll() {
    banner.current.querySelectorAll("span").forEach((span)=>{
      const bannerTop = window.scrollY-banner.current.offsetTop+(window.innerHeight/3)
      const transform = bannerTop > 0
						? 0
            :Math.floor(((bannerTop)*0.05)*2*span.dataset.prlx),
          opacity = (1 + (bannerTop/(window.innerHeight/2))) > 0
					? (1 + (bannerTop/(window.innerHeight/2))).toFixed(2)
					: 0;
      span.style.transform = `translateX(${transform}px)`;
      span.style.opacity = opacity;
    })
  }

  return (
    <section
      className="section section--gradient"
      style={{
        backgroundColor: 'black'
      }}
      ref={banner}
    >
      <div className="container">
        <div className="column is-12 has-text-centered">
          <h1
            className={bannerClass}
          >
            {
              text.split(" ").map((word, i) => {
                if (word === highlightText){
                  return <span data-prlx={0} style={{color: highlightColor, display: 'inline-block', marginRight:'.2em'}}>{word} </span>;
                } else {
                  return <span data-prlx={Math.floor(Math.random() * (6 + 6) - 6)} style={{display: 'inline-block', marginRight:'.2em'}}>{word} </span>
                }
              })
            }
          </h1>
        </div>
      </div>
    </section>
  );
}
export default TextBanner
